import React, { useState } from "react";
import img from "../assets/images/Frame.png";
import img2 from "../assets/images/icon.png";
import { Button, TextField } from "@mui/material";
import { Animated } from "react-animated-css";
import Signin from "../component/login/Signin";
import Signup from "../component/login/Signup";
const Login = () => {
  const [toggle, settoggle] = useState("signin");

  return (
    <div className="w-screen h-screen flex overflow-hidden">
      <div className="basis-[50%] flex justify-start flex-col items-center">
        <img src={img2} className="h-[calc(100vh/5)]" />
        {toggle == "signin" && (
          <Animated
            animationIn="flipInY"
            animationOut="fadeOut"
            isVisible={toggle === "signin"}
          >
            <Signin settoggle={settoggle} />
          </Animated>
        )}
        {toggle == "signup" && (
          <Animated
            animationIn="flipInY"
            animationOut="fadeOut"
            isVisible={toggle === "signup"}
          >
            <Signup settoggle={settoggle} />
          </Animated>
        )}
      </div>

      <div className="basis-[50%] bg-[#F1EDFD] flex justify-center items-center">
        <img src={img} className="mix-blend-darken" />
      </div>
    </div>
  );
};

export default Login;
