import React from 'react'
import img from "../../assets/images/avatar.png"
import { Avatar, Button } from '@mui/material'
import { styled } from '@mui/material/styles';
import { deleteDoc, doc } from 'firebase/firestore';
import { db } from '../../config/firebase';
import Swal from 'sweetalert2';

const Mycard = ({info,setFormstep,setArrayUsers}) => {

 

      const ColorButton = styled(Button)(({ theme }) => ({
        width:'81px',
        height:'25px',
        fontSize:'9px',
        fontWeight:700,
        color: theme.palette.getContrastText('#6F47EB'),
        backgroundColor: '#6F47EB',
        '&:hover': {
          backgroundColor: '#6F47EB',
          
        },
      }));

      const viewhistory = () =>{


        
      }
      const deletethismember = ()=>{
        
        const docRef = doc(db, "users", info.id);
        deleteDoc(docRef).then(() => {
          Swal.fire({
            title: "Success!",
            text: "Deleted successfully!",
            icon: "success",
      
          
          })
          
          setArrayUsers((prev)=>prev.filter((obj)=>{

            if(obj.id!=info.id)
            return obj

          }))
      })
      .catch(error => {
          console.log(error);
      })

      
      }
  return (
    <div className='w-[233px] h-[128px] flex flex-col bg-white rounded-[8px] border-[0.3px] cursor-pointer border-solid border-[#CCCCCC] shadow-[2px_8px_24px_rgba(71,71,71,0.07)]' >
    <div className='flex  items-center gap-x-[10px] p-[5px] h-[60%] border-b-slate-100 border-b-[1px]'>
    <Avatar src={info.photo&&info.photo} sx={{height:56,width:56}} />
  
        <div className='flex flex-col justify-center '>
            <h2 className='text-[16px] font-[700]'>{info.firstname+" "+info.lastname}</h2>
            <p className='text-[10px] text-[#616161b0] relative bottom-[5px] font-[500]'>{info.email}</p>
        </div>
    </div>
    <div  className='flex justify-center items-center gap-x-[10px] h-[40%] '>
     <Button sx={{width:81,height:25,fontSize:9,fontFamily:"Arial",fontWeight:700,color:"#6F47EB",borderColor: '#6F47EB',}} variant="outlined" size="small" onClick={()=>setFormstep(info)}>View</Button>
      <ColorButton variant="contained" onClick={()=>deletethismember()}>Delete</ColorButton>

    </div> 


    </div>
  )
}

export default Mycard