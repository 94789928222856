import React, { useEffect, useState } from "react";
import Heading from "../searchhistory/Heading";
import { Button, Chip, Divider } from "@mui/material";
import ChatButton from "../searchhistory/ChatButton";

import {
  collection,
  addDoc,
  getDocs,
  set,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { setCurrentScreen } from "firebase/analytics";
import { KeyboardArrowLeft } from "@mui/icons-material";
import Chating from "../chating/Chating";
import Showchat from "./Showchat";

const Showhistory = ({ info, setFormstep }) => {
  const [todos, setTodos] = useState([]);
  console.log(info);
  useEffect(() => {
    const fetchPost = async () => {
      await getDocs(collection(db, info.id)).then((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        console.log(newData);
        setTodos(newData);
        console.log(todos, newData);
      });
    };
    fetchPost();
  }, []);

  const deleteallhistory = () => {
    {
      todos.forEach((obj) => {
        const docRef = doc(db, info.id, obj.id);
        deleteDoc(docRef).then(() => {
          console.log("Entire Document has been deleted successfully.");
          setTodos([]);
        });
      });
    }
  };

  const oldChat = (obj) => {
    setuniqueid(obj.id);

    setprevChat(Object.entries(obj));
    setScreen(1);
  };
  const [uniqueid, setuniqueid] = useState();
  const [prevChat, setprevChat] = useState([]);
  const [screen, setScreen] = useState(0);
  return (
    <>
      <div
        className="flex flex-col items-center   overflow-y-scroll"
        id="your_div"
      >
        {screen == 0 ? (
          <>
            <div className="mt-6">
              <Divider className="flex justify-between w-[70vw]">
                <Button size="small" onClick={() => setFormstep(0)}>
                  <Chip
                    label={`This is chat history of ${info.firstname} ${info.lastname}`}
                  />
                  <KeyboardArrowLeft />
                  Back
                </Button>
              </Divider>
            </div>
            {/* <Chip label={`This is chat history of ${info[Object.keys(info)[0]]["full name"]}`} /> */}

            <div className="w-[800px] border-[1px] border-solid border-b-gray-200 p-[15px] pr-0 mt-[20px] rounded">
              <h2 className="font-semibold">Search History</h2>
              <div className="max-h-[52vh] overflow-y-auto pr-[15px]">
                {" "}
                {todos.length > 0 ?
                  todos.map((obj) => {
                    return (
                      <div className="flex justify-between my-5">
                        <Heading data={obj} info={info} />
                        <div onClick={() => oldChat(obj)}>
                          <ChatButton />
                        </div>
                      </div>
                    );
                  }):

                  <div className="flex justify-center my-5 ">
                    <h2 className="text-center">No Chat History</h2>
                  </div>
                  
                  }
              </div>

              <div className="flex justify-center border-t-[1px] border-t-gray-200 pt-4 mb-1 ">
                <button
                  onClick={deleteallhistory}
                  className="border-[1px] bg-[#EDEDED] rounded-3xl text-[#737679] h-fit px-[10px] py-[3px]"
                >
                  Clear Chat History
                </button>
              </div>
            </div>
          </>
        ) : (
          <Showchat setScreen={setScreen} info={info} prevChat={prevChat} />
        )}
      </div>
    </>
  );
};

export default Showhistory;
