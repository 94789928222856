import React, { useEffect, useRef, useState } from "react";
import img from "../../assets/images/icon2.jpg";
import img2 from "../../assets/images/avatar.png";
import "../chating/chating.css"
import { Avatar, Button, Chip, Divider } from "@mui/material";
import { KeyboardArrowLeft } from "@mui/icons-material";
const Showchat = ({ chatArray ,prevChat,info,setScreen }) => {

  return (
<>
    <div className="mt-6">
    <Divider className="flex justify-between w-[70vw]">
    <Button size="small" onClick={()=>setScreen(0)} >
    <Chip label={`This is chat history of ${info.firstname} ${info.lastname}`} />
    
    
     
     <KeyboardArrowLeft />

   
   Back 

 </Button>
 
  </Divider>
  </div>
    <div className="w-[70vw] h-[82vh] pb-10 ">
      

      {prevChat.filter((obj)=>obj[0]!="id").sort((a,b)=>a[1].Timestamp-b[1].Timestamp).map((obj, i) => {
        
        return (
          <div >
            <div className="flex justify-end my-5 ">
              <h2 className="bg-[#EDEDED] p-3 rounded-md flex items-center gap-x-3 font-semibold">
                {obj[0]} <Avatar src={info.photo&&info.photo} sx={{height:35}} />
              </h2>
            </div>

            <div className="border border-gray-200 rounded-md ">
              <div className="flex justify-between p-4 items-center">
                <img src={img} className="h-[35px]" />
                <div>
                  {/* <span className="material-symbols-outlined mr-3">thumb_up</span>
                  <span className="material-symbols-outlined">thumb_down</span> */}
                </div>
              </div>
              <div className="px-5 pb-1">
                <pre className="inline-table w-full  whitespace-break-spaces"  >
                {obj[1].answer}
                </pre>
                {/* <p>{obj?.answer}</p> */}
              </div>
            </div>
          </div>
        );
      })}


      


    </div>
    </>  );
};

export default Showchat;
