import React, { useEffect, useState } from "react";
import { Button, TextField } from "@mui/material";
import {
  collection,
  addDoc,
  getDocs,
  set,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { LoadingButton } from "@mui/lab";
import SaveIcon from "@mui/icons-material/Save";
import Swal from "sweetalert2";
const Signup = ({ settoggle }) => {
  const [formObj, setFormobj] = useState({
    firstname: "",
    lastname:"",
    email: "",
    password: "",
  });
  const [loader, setloader] = useState(false);
  const [error, seterror] = useState([0, 0, 0]);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormobj({ ...formObj, [name]: value });
  };
  useEffect(() => {
    console.log(formObj);
  });

  const signup = async () => {
    let arr = [0, 0, 0];
        
 
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(formObj.email) ) {
      arr[1] = 1;
    }
    if (formObj.firstname === "") {
      arr[0] = 1;
    }
    if (formObj.password.length < 6) {
      arr[2] = 1;
    }
 
    seterror(arr);
   

    if (!arr.some((obj) => obj === 1)) {

                fetchemails().then(async(result)=>{

                    if(!result){

                    
                  
                }

                })


   
    }
}

  

  const fetchemails =  () => {

   return new Promise((resolve,reject)=>{

        getDocs(collection(db, "users")).then(async(querySnapshot) => {
            const newData = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
            console.log(newData);
      
              if( newData.some((obj) => {
                if (Object.keys(obj)[0] === formObj.email) {
                       
                  
            
                 return true;
                }
              }))
              {

                setFormobj({...formObj,email:""});
      
                Swal.fire({
                   icon: 'error',
                   title: 'Email already exist',
                   text: 'Use other email',

                 })

                 
              }
            else  {
                setloader(true);
                const docRef = await addDoc(collection(db, "users"), 
                  { "email":formObj.email,
                    "firstname": formObj.firstname,
                    "lastname" : formObj.lastname,
                    password: formObj.password,
                    photo:null,
                    Timestamp: Timestamp.now(),
                  },
                );
          
                Swal.fire({
                  title: "Success!",
                  text: "Your account created successfully!",
                  icon: "success",
          
                  confirmButtonColor: "#3085d6",
          
                  confirmButtonText: "Ok",
                }).then((result) => {
                  if (result.isConfirmed) {
                    settoggle("signin");
                  }
                });
          
                console.log("Document written with ID: ", docRef.id);
                setloader(false);
                            resolve(false)
              }
            
            
          });






    })


    
  };

  useEffect(() => {
    console.log(error);
  });
  return (
    <div className="flex flex-col gap-y-8 mt-12 ">
      <h2 className="text-[#6F47EB] text-[32px] font-[700] ">Signup</h2>
      <div className="h-[50px]">
        <TextField
          error={error[0] === 1}
          helperText={error[0] === 1 ? "Firstname must not empty." : false}
          id="outlined-basic"
          label="First Name"
          name="firstname"
          value={formObj.firstname}
          onChange={handleChange}
          variant="outlined"
          className="w-[calc(100vw/3.5)]"
        />
      </div>
      <div className="h-[50px]">
        <TextField
       
   
          id="outlined-basic"
          label="Last Name"
          name="lastname"
          value={formObj.lastname}
          onChange={handleChange}
          variant="outlined"
          className="w-[calc(100vw/3.5)]"
        />
      </div>
      <div className="h-[50px]">
        {" "}
        <TextField
          error={error[1] === 1}
          helperText={error[1] === 1 ? "Incorrect email address." : false}
          id="outlined-basic"
          label="Email"
          name="email"
          variant="outlined"
          value={formObj.email}
          onChange={handleChange}
          className="w-[calc(100vw/3.5)]"
        />
      </div>
      <div className="h-[50px]">
        <TextField
          error={error[2] === 1}
          helperText={
            error[2] === 1 ? "Password must be longer than 6 character." : false
          }
          type="email"
          id="outlined-basic"
          label="Password"
          name="password"
          variant="outlined"
          value={formObj.password}
          onChange={handleChange}
          className="w-[calc(100vw/3.5)]"
        />
      </div>

      {loader ? (
        <LoadingButton
          loading
          loadingPosition="start"
          className="h-[56px] w-full mt-16"
          startIcon={<SaveIcon />}
          variant="outlined"
        >
          Processing..
        </LoadingButton>
      ) : (
        <Button
          variant="contained"
          className="h-[56px] w-[calc(100vw/3.5)] bg-[#6F47EB]"
          onClick={signup}
        >
          Signup
        </Button>
      )}

      <h2 className="text-center font-[600] text-[16px]">
        Already have an account?{" "}
        <span className="text-[#6F47EB]" onClick={() => settoggle("signin")}>
          {" "}
          Login
        </span>
      </h2>
    </div>
  );
};

export default Signup;
