const navigationConfig = [
{
id:"homepage",
title:"AI Chat",
url:"/home",
icon: 'chat',
},
{
    id:"member",
    title:"Members",
    url:"/member",
    icon: 'group',
},
// {
//     id:"integragtions",
//     title:"Integerations",
//     url:"/integration",
//     icon: 'sync_alt',

// },
// {
//     id:"referfriend",
//     title:"Refer friends",
//     url:"/refer",
//     icon:'share',

// },
// {
//     id:"pricing",
//     title:"Pricing Plans",
//     url:'/pricing',
//     icon:"attach_money"

// },
{
    id:"setting",
    title:"Settings",
    url:"/setting",
    icon:"settings"
}

]

export default navigationConfig;