import React from 'react'
import img from "../../assets/images/avatar.png";
import { Avatar } from '@mui/material';
const Heading = ({data,info}) => {

  
  const myInterpret = Object.entries(data).sort((a,b)=>a[1].Timestamp-b[1].Timestamp);

  function timeSince(date) {

    var seconds = Math.floor((new Date() - date) / 1000);
  
    var interval = seconds / 31536000;
  
    if (interval > 1) {
      return Math.floor(interval) + " years";
    }
    interval = seconds / 2592000;
    if (interval > 1) {
      return Math.floor(interval) + " months";
    }
    interval = seconds / 86400;
    if (interval > 1) {
      return Math.floor(interval) + " days";
    }
    interval = seconds / 3600;
    if (interval > 1) {
      return Math.floor(interval) + " hours";
    }
    interval = seconds / 60;
    if (interval > 1) {
      return Math.floor(interval) + " minutes";
    }
    return Math.floor(seconds) + " seconds";
  }

  return (
  
    <div className='flex gap-x-[20px]'>

<Avatar src={info.photo&&info.photo} sx={{height:44,width:44}} />
        <div>
            <p className='font-medium text-base'>{myInterpret[0][0]}</p>
            <div className='flex text-gray-400' >
                <p className='text-sm font-normal'>{myInterpret.length-1} Questions Asked</p>
                <p className='ml-7 text-sm font-normal'><span className='font-extrabold text-gray-500 mr-2'>.</span>{timeSince(myInterpret[0][1].Timestamp.toDate())} ago</p>
            </div>
        </div>
    </div>
    
  )
}

export default Heading