import React, { useEffect, useState } from "react";
import Heading from "./Heading";
import { Button } from "@mui/material";
import ChatButton from "./ChatButton";

import {
  collection,
  addDoc,
  getDocs,
  set,
  updateDoc,
  doc,
  deleteDoc,
} from "firebase/firestore";
import { db } from "../../config/firebase";



const SearchHistory = ({
  setChatArray,
  setprevChat,
  uniqueid,
  setuniqueid,
}) => {
  const [todos, setTodos] = useState([]);
  const [loader,setloader] = useState(false)
  useEffect(() => {
    const fetchPost = async () => {
      await getDocs(collection(db, localStorage.getItem("user_token"))).then((querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));

        setloader(true);
        console.log(newData);
        setTodos(newData);
        console.log(todos, newData);
      });
    };
    fetchPost();
  }, []);

  const deleteallhistory = () => {
    {
      todos.forEach((obj) => {
        const docRef = doc(db, localStorage.getItem("user_token"), obj.id);
        deleteDoc(docRef).then(() => {
          console.log("Entire Document has been deleted successfully.");
          setTodos([]);
        });
      });
    }
  };

  const oldChat = (obj) => {
    setuniqueid(obj.id);

    setprevChat(Object.entries(obj));
  };

  return (
    <div className="w-[800px] border-[1px] border-solid border-b-gray-200 p-[15px] pr-0 mt-[20px] rounded">
      <h2 className="font-semibold">Search History</h2>
      <div className="max-h-[52vh] overflow-y-auto pr-[15px]">
        {loader &&


        <>
        {todos.length > 0 ?
          todos.map((obj) => {
            return (
              <div className="flex justify-between my-5">
                <Heading data={obj} info={JSON.parse(localStorage.getItem("user_info"))}/>
                <div onClick={() => oldChat(obj)}>
                  <ChatButton />
                </div>
              </div>
            );
          }):
          
          <div className="flex justify-center my-5 ">
          <h2 className="text-center">No Chat History</h2>
        </div>
          
          }

        </>

          }
      </div>

      <div className="flex justify-center border-t-[1px] border-t-gray-200 pt-4 mb-1 ">
        <button
          onClick={deleteallhistory}
          className="border-[1px] bg-[#EDEDED] rounded-3xl text-[#737679] h-fit px-[10px] py-[3px]"
        >
          Clear Chat History
        </button>
      </div>
    </div>
  );
};

export default SearchHistory;
