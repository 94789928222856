import "./App.css";
import { Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import PrivateRoute from "./route/PrivateRoute";
import Member from "./pages/Member";
import Setting from "./pages/Setting";
function App() {
  return (
    <Routes>
      <Route exact path="/" element={<Login />} />
      <Route
        exact
        path="/member"
        element={
          <PrivateRoute>
            <Member />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/home"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
      <Route
        exact
        path="/setting"
        element={
          <PrivateRoute>
            <Setting />
          </PrivateRoute>
        }
      />
      <Route
        path="*"
        element={
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        }
      />
    </Routes>
  );
}

export default App;
