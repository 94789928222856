import React, { useEffect, useRef, useState } from "react";
import img from "../../assets/images/icon2.jpg";
import img2 from "../../assets/images/avatar.png";
import { useScrollTrigger } from "@mui/material";
import "./chating.css"
const Chating = ({ chatArray ,prevChat }) => {

  useEffect(() => {
    // console.log(chatArray);

     type();
  }, [chatArray]);
  console.log("chatarry::", chatArray)

 
  let text = 'What follows is Lorem ipsum translated to English: But I must explain to you how all this mistaken idea of reprobating pleasure and extolling pain arose. To do so, I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects, dislikes or avoids pleasure itself, because it is pleasure, but because those who do not know how to pursue pleasure rationally encounter consequences...';
  let textLength = 0;

  const myElementRef = useRef(null);

  const  type =()=> {
    if(chatArray.length>0)
    {
      let textChar = chatArray[chatArray.length-1].answer.charAt(textLength++);
      let paragraph = document.getElementById(chatArray.length-1);
      let charElement = document.createTextNode(textChar);
      if(paragraph!=null)
      {
      paragraph.appendChild(charElement);
      if(textLength < chatArray[chatArray.length-1].answer.length+1) {
          setTimeout((textLength) => {
            type()
          }, 10);
          
      } else {
          if(textLength>3)
          {  var element = document.getElementById(chatArray.length-1);
            element.classList.remove("typed");
          }
      
        
          var objDiv = document.getElementById("your_div");
          objDiv.scrollTop = objDiv.scrollHeight;
 
      }
    }
    }else{


    }
    
  }
  





  return (
    <div className="w-[70vw] h-[calc(85vh-87px)] ">
      {prevChat.filter((obj)=>obj[0]!="id").sort((a,b)=>a[1].Timestamp-b[1].Timestamp).map((obj, i) => {
        
        return (
          <div >
            <div className="flex justify-end my-5 ">
              <h2 className="bg-[#EDEDED] p-3 rounded-md flex items-center gap-x-3 font-semibold">
                {obj[0]} <img src={JSON.parse(localStorage.getItem("user_info")).photo} className="h-[35px]" />
              </h2>
            </div>

            <div className="border border-gray-200 rounded-md ">
              <div className="flex justify-between p-4 items-center">
                <img src={img} className="h-[35px]" />
                <div>
                  {/* <span className="material-symbols-outlined mr-3">thumb_up</span>
                  <span className="material-symbols-outlined">thumb_down</span> */}
                </div>
              </div>
              <div className="px-5 pb-1">
                <pre className="inline-table w-full  whitespace-break-spaces"  >
                {obj[1].answer}
                </pre>
                {/* <p>{obj?.answer}</p> */}
              </div>
            </div>
          </div>
        );
      })}


      



      {chatArray.map((obj, i) => {
        console.log("obj::>", obj)
        return (
          <div key={i}>
            <div className="flex justify-end my-5 ">
              <h2 className="bg-[#EDEDED] p-3 rounded-md flex items-center gap-x-3 font-semibold">
                {obj.question} <img src={JSON.parse(localStorage.getItem("user_info")).photo} className="h-[25px]" />
              </h2>
            </div>

            <div className="border border-gray-200 rounded-md ">
              <div className="flex justify-between p-4 items-center">
                <img src={img} className="h-[35px]" />
                <div>
                  {/* <span className="material-symbols-outlined mr-3">thumb_up</span>
                  <span className="material-symbols-outlined">thumb_down</span> */}
                </div>
              </div>
              <div className="px-5 pb-1">
                <pre className="inline-table w-full typed whitespace-break-spaces" id={i} >
                </pre>
                {/* <p>{obj?.answer}</p> */}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default Chating;
