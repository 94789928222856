import React, { useEffect, useState } from "react";
import Sidebar from "../component/sidebar/Sidebar";
import Navbar from "../component/navbar/Navbar";
import { Avatar, Button, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material";
import { styled } from "@mui/material/styles";

import { FileUploader } from "react-drag-drop-files";
import { doc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";
import Swal from "sweetalert2";
import { Visibility, VisibilityOff } from "@mui/icons-material";


const fileTypes = ["JPEG", "PNG", "GIF"];
const Setting = () => {
  const ColorButton = styled(Button)(({ theme }) => ({
    width: "114px",
    height: "37px",
    fontSize: "15px",
    fontWeight: 700,
    marginLeft: 20,
    color: theme.palette.getContrastText("#6F47EB"),
    backgroundColor: "#6F47EB",
    "&:hover": {
      backgroundColor: "#6F47EB",
    },
  }));

  const [formObj, setFormobj] = useState(JSON.parse(localStorage.getItem('user_info')));
  const [loader, setloader] = useState(false);
  const [error, seterror] = useState([0, 0, 0]);
  const handleChange = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormobj({ ...formObj, [name]: value });
  };
  const [file, setFile] = useState(null);
  function getBase64(file) {
    var reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      
       setFormobj({...formObj,photo:reader.result})
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }
  const handleChangeFile = (file) => {
    setFile(file);
    getBase64(file);
    // setFormobj({...formObj,photo:URL.createObjectURL(file)})
  };


  const saveInfo = async (question,answer) => {

    
    await updateDoc(doc(db, "users",localStorage.getItem("user_token")), {...formObj});
    
    localStorage.setItem("user_info",JSON.stringify(formObj))
    setrefresh(refresh+1);
    Swal.fire({
      title: "Success!",
      text: "Saved successfully!",
      icon: "success",

    
    })

  };

  const [refresh,setrefresh]=useState(0);
  useEffect(()=>{

    console.log(formObj);
  })

  const [showPassword, setShowPassword] = useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <div className="flex">
      <Sidebar />

      <div className="w-[80vw]">
        <Navbar  />

        <div className="py-[36px] px-[56px]">
          <div className="flex justify-between items-center">
            <div>
              <h2 className="font-[600] text-[24px] ">Setting</h2>

              <p className="font-[400] text-[20px] text-[#8F8F8F]">
                Update your personal details and photo here
              </p>
            </div>
            <div>
              {/* <Button
                sx={{
                  width: 114,
                  height: 37,
                  fontSize: 15,
                  fontFamily: "Arial",
                  fontWeight: 700,
                  color: "#6F47EB",
                  borderColor: "#6F47EB",
                }}
                variant="outlined"
                size="small"
              >
                Cancel
              </Button> */}
              <ColorButton variant="contained" onClick={saveInfo}>Save</ColorButton>
            </div>
          </div>
          <div className="flex justify-between gap-x-[36px] mt-[24px]">
          <div className="bg-[#F9F9F9] rounded-[24px] border-b-8  w-[70%]">
            <div className="px-[28px] py-[16px] border-b-2 border-[#b9b8b8]">
              <h3 className="font-[600] text-[20px] ">Personal Information</h3>
            </div>
            <div className="py-[32px] px-[28px]">
              <div className="flex gap-x-8">
                <TextField
                  error={error[0] === 1}
                  helperText={
                    error[0] === 1 ? "Fullname must not empty." : false
                  }
                  id="outlined-basic"
                  label="First Name"
                  name="firstname"
                  value={formObj.firstname}
                  onChange={handleChange}
                  variant="outlined"
                  className="w-[50%]"
                  sx={{backgroundColor:"#ffff"}}
                />
                <TextField
                  error={error[0] === 1}
                  helperText={
                    error[0] === 1 ? "Fullname must not empty." : false
                  }
                  id="outlined-basic"
                  label="Last Name"
                  name="lastname"
                  value={formObj.lastname}
                  onChange={handleChange}
                  variant="outlined"
                  className="w-[50%]"
                  sx={{backgroundColor:"#ffff"}}
                />
              </div>
              <div className="flex gap-x-8 mt-[24px]">

              <FormControl sx={{ width: '50%' }} variant="outlined">
          <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
          <OutlinedInput
            id="outlined-adornment-password"
            type={showPassword ? 'text' : 'password'}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
            value={formObj.password}
            sx={{backgroundColor:"#ffff"}}
            onChange={handleChange}
          />
          
        </FormControl>
    

            
                <TextField
                  error={error[0] === 1}
                  helperText={
                    error[0] === 1 ? "Fullname must not empty." : false
                  }
                  id="outlined-basic"
                  label="Email"
                  name="fullname"
                  value={formObj.email}
                  onChange={handleChange}
                  variant="outlined"
                  className="w-[50%]"
                  sx={{backgroundColor:"#ffff"}}
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="bg-[#F9F9F9] rounded-[24px] w-[30%]  border-b-8 ">
          <div className="px-[28px] py-[16px] border-b-2 border-[#b9b8b8]">
              <h3 className="font-[600] text-[20px] ">Your Photo</h3>
            </div>
            <div className="flex justify-center items-center px-[28px] py-[16px]">
              <Avatar src={formObj.photo&&formObj.photo}   sx={{ width: 56, height: 56 }}/>
           
            
            </div>
            <div className="w-[288px] h-[158px] border-dashed border-2 m-auto mb-[14px] rounded-[4px] ">
            <FileUploader
            
     classes="!h-[100%] !max-w-[100%] !min-w-[100%]"
        handleChange={handleChangeFile}
        name="file"
        types={fileTypes}
      />
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Setting;
