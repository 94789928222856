import React from 'react'
import img from "../../assets/images/avatar.png"
import { Avatar, Menu, MenuItem } from '@mui/material'
import { useNavigate } from 'react-router-dom';
const Navbar = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const navigate = useNavigate()  ;
  const handleClose = (action) => {
    setAnchorEl(null);
    if(action==="logout")
    {
    localStorage.removeItem("user_token");
  
    navigate('/')
  }
  };

  return (
    <div className='w-[80vw] h-[3.2rem] border-b-[1px] border-solid border-b-gray-200 flex items-center justify-end'>
          <div className='pr-6 flex gap-x-3 items-center font-semibold'>
             {JSON.parse(localStorage.getItem("user_info"))['firstname']+" "+JSON.parse(localStorage.getItem("user_info"))['lastname']}
          <Avatar src={JSON.parse(localStorage.getItem("user_info"))['photo']} sx={{ width:40, height: 40 }}
          
          aria-controls={open ? 'basic-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={open ? 'true' : undefined}
          onClick={handleClick}


          />
          </div>
          <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
    
        <MenuItem onClick={()=>navigate("/setting")}>My Account</MenuItem>
        <MenuItem onClick={()=>handleClose("logout")}>Logout</MenuItem>
      </Menu>
      
    </div>
  )
}

export default Navbar