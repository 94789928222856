import React, { useEffect, useState } from 'react'
import Sidebar from '../component/sidebar/Sidebar'
import Navbar from '../component/navbar/Navbar'
import Mycard from '../component/card/Card'
import {
    collection,    
    getDocs,

  } from "firebase/firestore";
  import { db } from "../config/firebase";
import Showhistory from '../component/showcsasehistory/Showhistory';
import { useNavigate } from 'react-router-dom';

const Member = () => {

    
    const [arrayUsers,setArrayUsers]=useState([]);
    const [formStep,setFormstep] = useState(0)

      useEffect(()=>{
        getDocs(collection(db, "users")).then(async(querySnapshot) => {
            const newData = querySnapshot.docs.map((doc) => ({
              ...doc.data(),
              id: doc.id,
            }));
            console.log(newData);
            setArrayUsers(newData);
          });

      },[])


      const navigate = useNavigate();

      if(JSON.parse(localStorage.getItem("user_info")).email!="admin@gmail.com")
      {

          navigate("/home");
      }
    
  return (
<>
 
      <div className="flex">
      <Sidebar />
      
      <div className='w-[80vw]'>
        <Navbar />
        {formStep==0?
        <div className="flex flex-wrap py-6 gap-x-[46px] gap-y-[46px]  px-12 items-center   overflow-y-scroll" id="your_div">
         {arrayUsers.length>0 && arrayUsers.filter(x=>x.email!="admin@gmail.com").map((obj)=>{
             
              return <Mycard setFormstep={setFormstep} setArrayUsers={setArrayUsers}   info={obj}/>
  
         })
  
  
         }
       
    
        </div>
        :
        <Showhistory info={formStep} setFormstep={setFormstep}/>
    
        }
      </div>
    </div>
   </>
  )
}

export default Member