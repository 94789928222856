import React, { useState } from "react";
import { Button, TextField } from "@mui/material";
import Swal from "sweetalert2";
import SaveIcon from "@mui/icons-material/Save";
import {
  collection,
  addDoc,
  getDocs,
  set,
  updateDoc,
  doc,
  Timestamp,
} from "firebase/firestore";
import { db } from "../../config/firebase";
import { Navigate, json, useNavigate } from "react-router-dom";
import { LoadingButton } from "@mui/lab";

const Signin = ({ settoggle }) => {
  const [loader, setloader] = useState(false);
  const [formObj, setFormobj] = useState({ email: "", password: "" });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormobj({ ...formObj, [name]: value });
  };
  const submit = () => {
    setloader(true);
    fetchemails();
  };

  const navigate = useNavigate();

  const fetchemails = () => {
    return new Promise((resolve, reject) => {
      getDocs(collection(db, "users")).then(async (querySnapshot) => {
        const newData = querySnapshot.docs.map((doc) => ({
          ...doc.data(),
          id: doc.id,
        }));
        console.log(newData);
          setloader(false)
        if (
          newData.some((obj) => {
            
            if (
              obj.email == formObj.email &&
              obj.password == formObj.password
            ) {
              
              localStorage.setItem("user_token", obj.id);
              localStorage.setItem("user_info",JSON.stringify({
                  firstname:obj.firstname,
                  lastname:obj.lastname,
                  email:obj.email,
                  password:obj.password,
                  photo:obj.photo,
              }))
              return true;
            }
          })
        ) {
          navigate("/home");
        } else {
          Swal.fire({
            icon: "error",
            title: "Username and password is incorrect",
            text: "Enter correct credentials",
          });
        }
      });
    });
  };

  return (

    <div className="flex flex-col gap-y-8 mt-12 ">
      <h2 className="text-[#6F47EB] text-[32px] font-[700] ">Login</h2>

      <TextField
        id="outlined-basic"
        label="Email"
        name="email"
        value={formObj.email}
        onChange={handleChange}
        variant="outlined"
        className="w-[calc(100vw/3.5)]"
      />

      <TextField
        id="outlined-basic"
        label="Password"
        type="password"
        name="password"
        value={formObj.password}
        onChange={handleChange}
        variant="outlined"
        className="w-[calc(100vw/3.5)]"
        onKeyDown={(e)=>(e.key === 'Enter')&&submit()}
      />
      {!loader?
   <Button
   variant="contained"
   className="h-[56px] w-[calc(100vw/3.5)] bg-[#6F47EB]"
   onClick={submit}
 >
   Login
 </Button>:
    <LoadingButton
    loading
    loadingPosition="start"
    className=" h-[56px] w-[calc(100vw/3.5)]"
    startIcon={<SaveIcon />}
    variant="outlined"
  >
    Processing..
  </LoadingButton>

      }
   

  

      {/* <h2 className="text-[#6F47EB] text-[14px] font-[600] text-center relative bottom-2">
        Forgot your password?
      </h2> */}
      <h2 className="text-center font-[600] text-[16px]">
        Don’t have an account?{" "}
        <span className="text-[#6F47EB]" onClick={() => settoggle("signup")}>
          Signup
        </span>
      </h2>
    </div>
  );
};

export default Signin;
